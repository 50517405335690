import React, { useState, Fragment, useRef } from 'react';
import { Drawer, Typography, Avatar, Row, message } from 'antd';
import {
	UserOutlined,
	PoweroffOutlined,
	SettingOutlined,
	ShoppingCartOutlined,
	DollarOutlined,
	CloseSquareOutlined,
	DownloadOutlined,
	UploadOutlined,
	FileTextOutlined,
	EditOutlined,
	DropboxOutlined,
	PlusOutlined
} from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';

import './Navbar.scss';
import { logOut } from '../../redux/actions/session';
import { toggleMenu } from '../../redux/actions/navbar';
import ModalDiscount from '../ModalDiscount/ModalDiscount';
import ModalIO from '../ModalIO/ModalIO';
import ModalRequestAdjust from '../ModalRequestAdjust/ModalRequestAdjust';
import http from '../../http';

const { Title } = Typography;
const Navbar = ({ createProduct }) => {
	const subMenuBtn = useRef();
	const history = useHistory();
	const dispatch = useDispatch();
	const { session, navbar } = useSelector((state) => ({
		session: state.session,
		navbar: state.navbar
	}));

	const [showSubMenu, setShowSubMenu] = useState(false);
	const [showModalDiscount, setShowModalDiscount] = useState(false);
	const [modalIO, setModalIO] = useState({ visible: false });
	const [showModalRequestAdjust, setShowModalRequestAdjust] = useState(false);

	const handleLogout = () => {
		dispatch(toggleMenu());
		http.post('/auth/logout')
			.then(() => {
				dispatch(logOut());
				history.push('/login');
			})
			.catch(() => message.error('No se pudo cerrar la sesion'));
	};

	const handleClose = () => {
		dispatch(toggleMenu());
	};

	const toggleSubMenu = () => {
		setShowSubMenu(!showSubMenu);
		if (showSubMenu) {
			subMenuBtn.current.classList.remove('active');
		} else {
			subMenuBtn.current.classList.add('active');
		}
	};

	const name = () => {
		let out;
		const { name } = session;

		if (!name)
			return 'N/A'
		
		name.split(' ').forEach((name, index) => {
			if (index === 0) {
				out = name;
			} else if (name.replace(/ /g, ' ')) {
				out += ` ${name.charAt(0)}.`;
			}
		});

		return out;
	};

	return (
		<Fragment>
			<ModalDiscount
				visible={showModalDiscount}
				close={() => setShowModalDiscount(false)}
			/>

			<ModalIO
				{...modalIO}
				close={() => setModalIO({ visible: false })}
			/>

			<ModalRequestAdjust
				visible={showModalRequestAdjust}
				close={() => setShowModalRequestAdjust(false)}
			/>

			<Drawer
				id="navbar"
				placement="left"
				onClose={handleClose}
				visible={navbar.isVisible}
				width={showSubMenu ? 701 : 350}
			>
				<Row justify="center">
					<Avatar size={130} icon={<UserOutlined />} src="" />
				</Row>
				<br />
				<Row justify="center">
					<Title>{name()}</Title>
				</Row>
				<br />
				<br />

				<div id="menus">
					<ul className="left">
						<li>
							<NavLink to="/profile">
								<UserOutlined />
								Mi Perfil
							</NavLink>
						</li>
						<li>
							<span
								className="btn"
								onClick={() => {
									createProduct({ visible: true, barcode: '' })
									handleClose()
								}}
							>
								<PlusOutlined />
								Nuevo Producto
							</span>
						</li>
						<li>
							<span
								className="btn"
								onClick={toggleSubMenu}
								ref={subMenuBtn}
							>
								<ShoppingCartOutlined />
								Opciones de Caja
							</span>
						</li>
						<li className={(!session.shift) ? 'disabled': ''}>
							<NavLink to="/cash-check" disabled={(!session.shift)}>
								<DollarOutlined />
								Arqueo
							</NavLink>
						</li>
						<li className={(!session.shift) ? 'disabled': ''}>
							<NavLink to="/shift-end" disabled={(!session.shift)}>
								<CloseSquareOutlined />
								Cierre de Caja
							</NavLink>
						</li>
						<li>
							<NavLink to="/products">
								<DropboxOutlined />
								Productos
							</NavLink>
						</li>
						{(session.role === 'ADMIN') &&
							<li>
								<NavLink to="/settings">
									<SettingOutlined />
								Configuraciones
							</NavLink>
							</li>
						}
						<li>
							<span className="btn" onClick={handleLogout}>
								<PoweroffOutlined />
								Cerrar Sesion
							</span>
						</li>
					</ul>

					{showSubMenu && (
						<Fragment>
							<span id="vertical_divider"></span>
							<ul>
								<li>
									<span
										className="btn"
										onClick={() => {
											setShowModalDiscount(true);
											handleClose();
											toggleSubMenu();
										}}
									>
										<DollarOutlined />
										Descuento
									</span>
								</li>
								<li>
									<NavLink to="/credit-note">
										<FileTextOutlined />
										Nota de Credito
									</NavLink>
								</li>
								<li className={(session.role === 'ADMIN') ? 'disabled': ''}>
									<span
										className="btn"
										onClick={() => {
											if (session.shift && session.role !== 'ADMIN')
												setModalIO({
													visible: true,
													type: 'IN'
												})
										}}
									>
										<DownloadOutlined />
										Ingreso
									</span>
								</li>
								<li  className={(session.role === 'ADMIN') ? 'disabled': ''}>
									<span
										className="btn"
										onClick={() => {
											if (session.shift && session.role !== 'ADMIN')
												setModalIO({
													visible: true,
													type: 'OUT'
												})
										}}
									>
										<UploadOutlined />
										Egreso
									</span>
								</li>
								<li>
									<span
										className="btn"
										onClick={() =>
											setShowModalRequestAdjust(true)
										}
									>
										<EditOutlined />
										Ajuste de precio
									</span>
								</li>
							</ul>
						</Fragment>
					)}
				</div>
			</Drawer>
		</Fragment>
	);
};

export default React.memo(Navbar);
