import moment from 'moment';
import { uuid } from 'uuidv4';

import {
	ADD_PRODUCT,
	REMOVE_PRODUCT,
	INCREASE_QUANTITY,
	DECREASE_QUANTITY,
	SET_QUANTITY,
	SET_PRICE,
	SET_DISCOUNT,
	PAUSE,
	REMOVE_PAUSED_TICKET,
	RESTART_TICKET,
	FINISH_TICKET,
	CLEAR
} from '../actions/cart';

const initialState = { discount: 0, products: [], paused: [] };

const cart = (state = initialState, action) => {
	switch (action.type) {
		case ADD_PRODUCT:
			const products = state.products;
			const { barcode } = action.payload
			const productAlreadyExists = products.find(
				(product) => product.id === action.payload.id
			);

			if (productAlreadyExists && barcode !== '999') {
				return {
					...state,
					products: products.map((product) =>
						product.id === action.payload.id
							? { ...product, quantity: product.quantity + 1 }
							: product
					)
				};
			} else {
				return {
					...state,
					products: [
						{
							...action.payload,
							quantity: 1
						},
						...products
					]
				};
			}
		case REMOVE_PRODUCT:
			return {
				...state,
				products: state.products.filter((product, index) => index !== action.payload.index)
			};
		case INCREASE_QUANTITY: {
			const product = (action.payload.id)
				? state.products.find(product => product.id === action.payload.id)
				: state.products[0]
			
			product.quantity++

			return {
				...state,
				products: state.products.map((item) =>
					(item.id === action.payload.id)
						? product
						: item
				)
			};
		}
		case DECREASE_QUANTITY: {
			const product = (action.payload.id)
				? state.products.find(product => product.id === action.payload.id)
				: state.products[0]
			
			product.quantity--

			return {
				...state,
				products: state.products.map((product) =>
					product.id === action.payload.id
						? product
						: product
				)
			};
		}
		case SET_PRICE: {
			return {
				...state,
				products: state.products.map((product) =>
					product.id === action.payload.id
						? { ...product, price: action.payload.price }
						: product
				)
			};
		}
		case SET_QUANTITY:
			return {
				...state,
				products: state.products.map((product) =>
					product.id === action.payload.id
						? { ...product, quantity: action.payload.quantity }
						: product
				)
			};
		case SET_DISCOUNT:
			return {
				...state,
				discount: action.payload.discount || 0
			};
		case PAUSE:
			const paused = [
				...state.paused,
				{
					id: uuid(),
					discount: state.discount,
					products: state.products,
					clientName: action.payload.clientName,
					date: moment(),
					userName: action.payload.userName
				}
			];

			return {
				discount: 0,
				products: [],
				paused
			};
		case REMOVE_PAUSED_TICKET:
			return {
				...state,
				paused: state.paused.filter(
					(ticket) => ticket.id !== action.payload.id
				)
			};
		case RESTART_TICKET:
			const ticket = {
				...state.paused.find(
					(ticket) => ticket.id === action.payload.id
				)
			};
			delete ticket.id;
			delete ticket.userName;
			delete ticket.clientName;
			delete ticket.date;

			return {
				...ticket,
				paused: state.paused.filter(
					(ticket) => ticket.id !== action.payload.id
				)
			};
		case FINISH_TICKET:
			const { finish } = action.payload;
			
			if (!finish || (finish && state.products.length > 0)) {
				return {
					...state,
					finish: action.payload.finish
				}
			}

			return state
		case CLEAR:
			return {
				...initialState,
				paused: state.paused
			};
		default:
			return state;
	}
};

export default cart;
