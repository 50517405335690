import React, { useState, useEffect } from 'react';
import {
	Modal,
	Table,
	Button,
	Input,
	InputNumber,
	Row,
	Divider,
	Typography
} from 'antd';
import {
	PlusOutlined,
	DeleteOutlined,
	ArrowLeftOutlined
} from '@ant-design/icons';
import Swal from 'sweetalert2';
import { useSelector } from 'react-redux';
import axios from 'axios';

import http from '../../http';
import { format } from '../../helper';

const { Search } = Input;
const { Text, Title } = Typography;
const API_URL = process.env.REACT_APP_API_URL;

const ModalRequestAdjust = ({ visible, close }) => {
	const { userId, shopId } = useSelector((state) => ({
		userId: state.session.id,
		shopId: state.shop.id
	}));

	const [searching, setSearching] = useState(false);
	const [loading, setLoading] = useState(false);
	const [sending, setSending] = useState(false);
	const [request, setRequest] = useState([]);
	const [products, setProducts] = useState([]);
	const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
	const [filters, setFilters] = useState({});

	useEffect(() => {
		searchProducts({ current: 1, pageSize: 10 }, filters);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filters]);

	const searchProducts = (pagination) => {
		setLoading(true);
		http.get('/products/table', {
			params: {
				pagination,
				filters
			}
		})
			.then((res) => {
				if (res) {
					const { products, pagination } = res.data;
					setProducts(products);
					setPagination(pagination);
				}
				setLoading(false);
			})
			.catch((error) => {
				setLoading(false);
				Swal.fire(
					'Error',
					'No se ha podido obtener la lista de productos.',
					'error'
				);
				console.error(error);
			});
	};

	const sendRequest = () => {
		setSending(true);
		const products = request.map((product) => ({
			productId: product.id,
			userId,
			shopId,
			currentPrice: product.price,
			newPrice: product.newPrice
		}));

		axios
			.post(API_URL + '/price-change-requests', products)
			.then(() => {
				setSending(false);
				close();
				Swal.fire('Solicitud enviada', '', 'success');
				setRequest([]);
			})
			.catch((error) => {
				setSending(false);
				Swal.fire(
					'Error',
					'No se ha podido enviar la solicitud',
					'error'
				);
				console.error(error);
			});
	};

	if (!navigator.onLine) {
		return (
			<Modal
				visible={visible}
				onCancel={close}
				title="Solicitar cambio de precio"
				footer={null}
				width={600}
			>
				<Row justify="center" align="middle" style={{ height: 300 }}>
					<Title>No hay conexion a internet :(</Title>
				</Row>
			</Modal>
		);
	}

	return (
		<Modal
			visible={visible}
			onCancel={close}
			title="Solicitar cambio de precio"
			footer={[
				<Button
					type="primary"
					onClick={sendRequest}
					loading={sending}
					key="BTN1"
				>
					Enviar Solicitud
				</Button>
			]}
			width={800}
		>
			{!searching && (
				<Button
					icon={<PlusOutlined />}
					onClick={() => setSearching(true)}
					className="sm"
				>
					Agregar producto
				</Button>
			)}

			{searching && (
				<Row justify="space-between" align="middle">
					<Button
						icon={<ArrowLeftOutlined />}
						onClick={() => setSearching(false)}
						className="sm"
					>
						Atras
					</Button>

					<Row style={{ width: 300 }} justify="end" align="middle">
						<Text>Buscar: </Text>
						<Search
							onSearch={(value) => setFilters({ search: value })}
							className="default"
							autoFocus
							style={{ width: 200 }}
						/>
					</Row>
				</Row>
			)}

			<Divider />

			<Table
				dataSource={searching ? products : request}
				rowKey={(record) => record.id}
				loading={loading}
				pagination={searching && pagination}
				onChange={searching && searchProducts}
				columns={[
					{
						title: 'Nombre',
						dataIndex: 'name'
					},
					{
						title: 'Barras',
						dataIndex: 'barcodes',
						render: (barcodes) => barcodes[0].barcode
					},
					{
						title: 'Precio Actual',
						dataIndex: 'price'
					},
					{
						title: 'Precio Nuevo',
						dataIndex: 'newPrice',
						render: (value, record) =>
							searching ? (
								<InputNumber
									className="default"
									onChange={(value) =>
										(record.newPrice = value)
									}
								/>
							) : (
								<Text>{format.cash(value)}</Text>
							)
					},
					{
						title: searching ? 'Agregar' : 'Eliminar',
						render: (value, record) => {
							if (searching) {
								return (
									<PlusOutlined
										style={{
											fontSize: 24,
											cursor: 'pointer'
										}}
										onClick={() => {
											if (record.newPrice) {
												setRequest([
													record,
													...request
												]);

												setSearching(false);
											} else {
												Swal.fire(
													'Oops!',
													'Debe agregar el nuevo precio.',
													'warning'
												);
											}
										}}
									/>
								);
							} else {
								return (
									<DeleteOutlined
										style={{
											fontSize: 24,
											cursor: 'pointer'
										}}
										onClick={() =>
											setRequest(
												request.filter(
													(product) =>
														product.id !== record.id
												)
											)
										}
									/>
								);
							}
						}
					}
				]}
			/>
		</Modal>
	);
};

export default React.memo(ModalRequestAdjust);
