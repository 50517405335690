import React, { useState, useEffect } from 'react';
import {
	Layout,
	Row,
	Form,
	Input,
	Button,
	Typography,
	Spin,
	Select
} from 'antd';
import { LoadingOutlined } from '@ant-design/icons'
import Swal from 'sweetalert2';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import axios from 'axios';

import './Login.scss';
import http from '../../http';
import { setSession, setShift } from '../../redux/actions/session';
import { setShop } from '../../redux/actions/shop';
import { setMeta } from '../../redux/actions/meta';

const { Title } = Typography;
const API_URL = process.env.REACT_APP_API_URL;

const Login = () => {
	const dispatch = useDispatch();
	const { session, shop } = useSelector((state) => ({
		session: state.session,
		shop: state.shop
	}));

	const [loading, setLoading] = useState(false);
	const [shops, setShops] = useState([]);
	const [error, setError] = useState(null);

	useEffect(() => {
		getCurrentShift();

		axios
			.get(API_URL + '/shops')
			.then((res) => {
				setLoading(false);
				const { data } = res;
				setShops(data.filter(shop => shop.id !== 0));
			})
			.catch(() => {
				setLoading(false);
				setError({
					title: 'Error desconocido.',
					text: 'No se ha podido obtener la lista de tiendas.'
				});
			});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const login = async (form) => {
		try {
			setLoading(true);
			const { data } = await http.post('/auth/login', form)
			dispatch(setSession(data));

			const shop = shops.find(({ id }) => id === form.shopId);
			dispatch(setShop(shop))

			window.location.reload();
		} catch (error) {
			setLoading(false);
			if (error.response.status === 401)
				setError(error.response.data);
			else {
				Swal.fire(
					'Error',
					'No hemos podido completar tu solicitud, por favor intentalo mas tarde.',
					'error'
				);
			}
		}
	};

	const getCurrentShift = async (isLoggedIn = session.isLoggedIn) => {
		try {
			setLoading(true);

			if (isLoggedIn) {
				const { data } = await http.get('/meta')
				dispatch(setMeta(data))
			}
		
			if (isLoggedIn && !session.shift) {
				const res = await http.get('/shifts/current/' + shop.id)
				dispatch(setShift(res.data));
			}
			setLoading(false);
		} catch (error) {
			setLoading(false);
			Swal.fire(
				'Error',
				'No se pudo obteber la informacion de turno.',
				'error'
			);
			console.error(error)
		}
	};

	if (session.isLoggedIn && (session.role === 'ADMIN' || session.shift)) 
		return <Redirect to="/main" />;

	if (!session.isLoggedIn && session.tfa)
		return <Redirect to="/2FA" />;

	if (session.isLoggedIn && !loading)
		return <Redirect to="/shift-start" />
	
	if (session.isLoggedIn && loading)
		return (
			<Layout id="login_container">
				<Spin indicator={<LoadingOutlined style={{ fontSize: 100 }} />} />
			</Layout>
		)

	return (
		<Layout id="login_container">
			<Title level={1} className="title">
				Tienda
			</Title>

			<Form layout="vertical" onFinish={login}>
				<Form.Item
					label="Tienda"
					name="shopId"
					rules={[{ required: true, message: 'Por favor selecciona una tienda.' }]}
				>
					<Select>
						{shops.map(({ name, id }) => (
							<Select.Option key={'shop-' + id} value={id}>
								{name}
							</Select.Option>
						))}
					</Select>
				</Form.Item>

				<Form.Item
					label="Nombre de usuario:"
					name="nickName"
					rules={[
						{ required: true, message: 'Nombre de usuario' }
					]}
					validateStatus={
						error?.error && error?.error === 'Nick'
							? 'error'
							: ''
					}
					help={
						error?.error && error?.error === 'Nick'
							? error?.message
							: null
					}
				>
					<Input autoFocus />
				</Form.Item>

				<Form.Item
					label="Contraseña:"
					name="password"
					rules={[{ required: true, message: 'Contraseña' }]}
					validateStatus={
						error?.error && error?.error === 'Password'
							? 'error'
							: ''
					}
					help={
						error?.error && error?.error === 'Password'
							? error?.message
							: null
					}
				>
					<Input.Password className="default" />
				</Form.Item>
				<br />

				<Row justify="center">
					<Button
						type="primary"
						htmlType="submit"
						loading={loading}
					>
						Ingresar
					</Button>
				</Row>
			</Form>
		</Layout>
	);
};

export default Login;
