import React from 'react';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';

import Router from './router';
import './App.less';
import store from './redux/store';
import http from './http';

Sentry.init({
	dsn: process.env.SENTRY_DNS
});

window.addEventListener('online', () => {
	http.post('/sync');
});

function App() {
	return (
		<Provider store={store}>
			<Router />
		</Provider>
	);
}

export default App;
