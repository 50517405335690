import React, { useEffect } from 'react';
import { Switch, Route, HashRouter, useLocation } from 'react-router-dom';

import Login from './views/Login/Login';
import Main from './views/Main/Main';
import PausedTickets from './views/PausedTickets/PausedTickets';
import Profile from './views/Profile/Profile';
import Settings from './views/Settings/Settings';
import CashCheck from './views/CashCheck/CashCheck';
import CreditNote from './views/CreditNote/CreditNote';
import Products from './views/Products/Products';
import TFA from './views/Login/2FA'
import ShiftStart from './views/Login/ShiftStart'

const Routes = () => {
	const location = useLocation();

	useEffect(() => {
		const path = location.hash.substr(1);
		localStorage.setItem('path', path);
		/*
			path will be used by axios interceptor to determinate location
			and decie if redirect
		*/
	});

	return (
		<HashRouter>
			<Switch>
				<Route exact path={['/login', '/']} component={Login} />
				<Route exact path="/2FA" component={TFA} />
				<Route exact path="/shift-start" component={ShiftStart} />
				<Route exact path="/main" component={Main} />
				<Route exact path="/paused" component={PausedTickets} />
				<Route exact path="/profile" component={Profile} />
				<Route exact path="/settings" component={Settings} />
				<Route exact path="/products" component={Products} />
				<Route
					exact
					path={['/shift-end', '/cash-check']}
					component={CashCheck}
				/>
				<Route exact path="/credit-note" component={CreditNote} />
			</Switch>
		</HashRouter>
	);
};

export default Routes;
